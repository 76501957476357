import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import AppsIcon from "@material-ui/icons/Apps";
import ExitIcon from "@material-ui/icons/ExitToApp";
import { useAuth0 } from "../../react-auth0-spa";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useAppContext } from "../../context";

const Link1 = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    color: "#fff",
  },
}));

function Header() {
  const classes = useStyles();
  const { logout } = useAuth0();
  const { isProd, toggleEnv } = useAppContext();

  const [state, setState] = React.useState({
    open: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  // const [isProd, setProd] = React.useState(false);

  const handleChangeEnv = useCallback(
    (event) => {
      toggleEnv();
    },
    [toggleEnv]
  );

  const Menu = () => (
    <AppBar position="static">
      <Toolbar>
        <img
          src="https://cdn.onoranzefunebricloud.com/logos/singolo_white.png"
          width="61"
          height="40"
          alt="logo"
        />
        <Typography variant="h6" className={classes.title}>
          OFC Mission Control
        </Typography>
        <Link
          component={Link1}
          to="/"
          color="secondary"
          style={{ marginLeft: "5px", marginRight: "5px" }}
        >
          <Button color="inherit">Apps</Button>
        </Link>
        |
        <Link component={Link1} to="/ci" color="secondary">
          <Button
            color="inherit"
            style={{ marginLeft: "5px", marginRight: "5px" }}
          >
            Pipelines
          </Button>
        </Link>
        |
        <Link component={Link1} to="/create-backup" color="secondary">
          <Button
            color="inherit"
            style={{ marginLeft: "5px", marginRight: "5px" }}
          >
            Create Backup
          </Button>
        </Link>
        |
        <Link component={Link1} to="/db/backup/restore" color="secondary">
          <Button
            color="inherit"
            style={{ marginLeft: "5px", marginRight: "5px" }}
          >
            Restore Backup
          </Button>
        </Link>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={isProd}
                onChange={handleChangeEnv}
                value="dev"
                inputProps={{ "aria-label": "checkbox" }}
              />
            }
            label="PROD"
          />
        </FormGroup>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer("open", true)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );

  const SideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer("open", false)}
      onKeyDown={toggleDrawer("open", false)}
    >
      <List>
        <Link component={Link1} to="/">
          <ListItem button key="apps">
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary="Apps" />
          </ListItem>
        </Link>
        <Link component={Link1} to="/ci">
          <ListItem button key="ci-status">
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Pipelines" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <ListItem button key="logout" onClick={() => logout()}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <Menu />
      <Drawer
        open={state.open}
        anchor={"right"}
        onClose={toggleDrawer("open", false)}
      >
        <SideList />
      </Drawer>
    </div>
  );
}

export default Header;
