import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function IframePage(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <iframe
        title={props.title}
        src={props.src}
        style={{
          marginTop: "-20px",
          marginLeft: "-20px",
          overflow: "hidden",
          height: "100vh",
          width: "calc(100vw - 200px)",
        }}
        height="100%"
        width="100%"
      />
    </div>
  );
}

export default IframePage;
